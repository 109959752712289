<template>
  <el-container style="height:calc(100vh);">

    <el-container style="height:calc(100vh);">

      <el-aside class="aside" width="25%">
        <el-header>
          <span class="left el-icon-arrow-left" @click="returnOn"></span>
          设置
        </el-header>
        <el-menu class="menu" router>
          <el-menu-item index="/shop-info" class="menu-item">
            <template slot="title">
              <div class="nav" @click="routerJump('店铺信息')">
                <span>店铺信息</span>
              </div>
            </template>
          </el-menu-item>
          <el-menu-item index="/staff-info" class="menu-item">
            <template slot="title">
              <div class="nav" @click="routerJump('员工信息')">
                <span>员工信息</span>
              </div>
            </template>
          </el-menu-item>
          <!-- <el-menu-item index="/door-shop-set" class="menu-item">
            <template slot="title">
              <div class="nav" @click="routerJump('门店设置')">
                <span>门店设置</span>
              </div>
            </template>
          </el-menu-item> -->
          <el-menu-item index="/preferential-set" class="menu-item">
            <template slot="title">
              <div class="nav" @click="routerJump('优惠设置')">
                <span>优惠设置</span>
              </div>
            </template>
          </el-menu-item>

          <el-menu-item index="/user-agreement" class="clearCache">
            <template slot="title">
              <div class="nav">
                <span>用户协议</span>
              </div>
            </template>
          </el-menu-item>
          <el-menu-item index="/version-info" class="menu-item">
            <template slot="title">
              <div class="nav">
                <span>版本信息</span>
              </div>
            </template>
          </el-menu-item>

          <el-menu-item class="clearCache">
            <template slot="title">
              <div class="nav nav-flex" @click="checkUpdate">
                <span>检查更新</span>
                <span class="hint">有新版本</span>
              </div>
            </template>
          </el-menu-item>
          <el-menu-item class="menu-item">
            <template slot="title">
              <div class="nav" @click="clearCache">
                <span>清除缓存</span>
              </div>
            </template>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-container>
        <router-view :dataName="activeItem"></router-view>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
  import {
    Loading
  } from 'element-ui';
  export default {
    data() {
      return {
        item: ['店铺信息', '员工信息', '门店设置', '优惠设置'],
        activeItem: '店铺信息',
        fullscreenLoading: false
      }
    },

    mounted() {

    },
    destroyed() {
      window.sessionStorage.removeItem('setPath')
    },
    methods: {
      routerJump(item) {
        this.activeItem = item
        window.sessionStorage.setItem('setPath', item)
      },

      // 返回上级
      returnOn() {
        let a = window.sessionStorage.getItem('activePath') ? window.sessionStorage.getItem('activePath') : '/work'
        this.$router.push({
          path: a
        })
      },
      // 检查更新
      checkUpdate() {
        const loading = this.$loading({
          lock: false,
          text: '检查更新...',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        setTimeout(() => {
          loading.close();
          this.$message({
            message: '当前已是最新版本',
            type: 'success'
          });
        }, 2000);
      },
      // 清除缓存
      clearCache() {
        let loading = this.$loading({
          lock: false,
          text: '缓存清除中...',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        setTimeout(() => {
          loading.close();
          this.$message({
            message: '缓存清除成功！',
            type: 'success'
          });
        }, 2000)
      },

    }
  };

</script>
<style lang="less" scoped>
  .el-header {
    position: relative;
    background-color: #fff;
    font-weight: bold;
    font-size: 16px;
    color: #333;
    height: 50px !important;
    line-height: 50px;
    text-align: center;
    border-bottom: 1px solid #e9e9e9;

    .left {
      position: absolute;
      left: 20px;
      top: 50%;
      transform: translateY(-50%);
      color: #46a6ff;
      font-size: 24px;
    }
  }

  // 左侧导航
  .aside {
    min-height: calc(100vh);
    background-color: #f9f9f9;
    border-right: 1px solid #e9e9e9;

    .menu {
      margin-top: 20px;

      .nav {
        color: #333;
        padding: 0 20px;
        background-color: #fff;
        border-bottom: 1px solid #e9e9e9;
      }

      .nav1 {
        background-color: #ecf5ff;
      }

      .nav-flex {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .hint {
          background-color: #f56c6c;
          color: #fff;
          line-height: 30px;
          padding: 0 15px;
          font-size: 13px;
          border-radius: 30px 30px 0 30px;
        }
      }
    }


    /deep/ .el-menu-item {
      padding: 0 !important;
      height: 50px;
      line-height: 50px;
      border-top: 1px solid #e9e9e9;

    }

    /deep/ .clearCache.el-menu-item {
      padding: 0 !important;
      height: 50px;
      line-height: 50px;
      border-top: 1px solid #e9e9e9;
      border-bottom: 1px solid #e9e9e9;
      margin-top: 20px;

    }
  }

  .main {
    background-color: #f9f9f9;
  }



  /deep/ .menu.el-menu {
    border-right: none !important;
    background-color: #f9f9f9;

  }

  .el-menu-item:focus,
  .el-menu-item:hover {
    background-color: #f9f9f9;

  }

  .main.el-main {
    padding: 0 !important;
  }

</style>
